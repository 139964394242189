<template>
  <table  >
    <tr style="background-color: #d1d5db; text-align: center">
      <th style="width: 5%">STT</th>
      <th style="width: 40%">Tên khoản</th>
      <th style="width: 10%">SL</th>
      <th style="width: 20%">ST</th>
      <th style="width: 25%">Thành tiền</th>
    </tr>
    <tr v-for="(item, index) in listData" :key="index">
      <td>{{index + 1}}</td>
      <td style="text-align: left">{{ item.name }}</td>
      <td >{{ item.sumMoney!==0||item.discount!==0? item.quantity:null  }}</td>
      <td style="text-align: right">
        {{ item.price | formatCurrency}}
      </td>
      <td style="text-align: right">{{ item.sumMoney | formatCurrency}}</td>
    </tr>
<!--    <tr>-->
<!--      <td-->
<!--          colspan="4"-->
<!--          style="-->
<!--                    font-weight: bold;-->
<!--                    text-align: right;-->
<!--                    background-color: #d1d5db;-->
<!--                  "-->
<!--      >-->
<!--        Tổng cộng-->
<!--      </td>-->
<!--      <td colspan="2" style="text-align: right">-->
<!--        <b>{{ tableData.sumMoney | formatCurrencyNew }}</b>-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td  v-if="tableData.beforeMoney >= 0" colspan="4" style="font-weight: bold; text-align: right">Nhà trường trả thừa (Tháng trước)</td>-->
<!--      <td  v-if="tableData.beforeMoney < 0" colspan="4" style="font-weight: bold; text-align: right">Nhà trường trả thiếu (Tháng trước)</td>-->
<!--      <td colspan="2" style="text-align: right">-->
<!--        {{ tableData.beforeMoney | formatCurrency}}-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td colspan="4" style="font-weight: bold; text-align: right" v-if="tableData.spendMoney >= 0">-->
<!--        Số tiền đã nhận-->
<!--      </td>-->
<!--      <td colspan="4" style="font-weight: bold; text-align: right" v-else>-->
<!--        Số tiền đã đóng-->
<!--      </td>-->
<!--      <td colspan="2" v-if="tableData.spendMoney === 0" style="text-align: right">-->
<!--        {{ spendMoney | formatCurrency}}-->
<!--      </td>-->
<!--      <td colspan="2"  style="text-align: right" v-if="tableData.spendMoney !== 0">-->
<!--        {{ tableData.spendMoney | formatCurrency}}-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td v-if="tableData.afterMoney <= 0" colspan="4" style="font-weight: bold; text-align: right">Nhân viên còn được nhận</td>-->
<!--      <td v-if="tableData.afterMoney > 0 && tableData.spendMoney !== 0" colspan="4" style="font-weight: bold; text-align: right">Nhà trường trả thừa</td>-->
<!--      <td v-if="tableData.afterMoney > 0 && tableData.spendMoney === 0" colspan="4" style="font-weight: bold; text-align: right">Nhân viên nộp thêm</td>-->
<!--      <td colspan="2" style="text-align: right" :style="{color: 'red' }">-->
<!--        {{ tableData.afterMoney | formatCurrency}}-->
<!--      </td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td colspan="6" style="text-align: left" :style="{ fontStyle: 'italic' }">-->
<!--        <i>Số tiền bằng chữ : {{ tableData.readMoney }}</i>-->
<!--      </td>-->
<!--    </tr>-->

    <tr>
      <td colspan="6" style="background-color: #d1d5db;">
        <table style="width: 100%;">
          <tr>
            <td style="text-align: center; font-weight: bold; width: 25%;">Tổng cộng</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.beforeMoney >= 0">Nhà trường trả thừa <br/>(Tháng trước)</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.beforeMoney < 0">Nhà trường trả thiếu <br/>(Tháng trước)</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.spendMoney >= 0">Số tiền đã trả</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.spendMoney < 0">Số tiền đã trả</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.afterMoney <= 0">Nhân viên được nhận</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.afterMoney > 0  && tableData.spendMoney !== 0">Nhà trường trả thừa</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.afterMoney > 0  && tableData.spendMoney === 0">Nhân viên nộp thêm</td>
          </tr>
          <tr>
            <td style="text-align: right; width: 25%;"><b>{{ tableData.sumMoney | formatCurrencyNew }}</b></td>
            <td style="text-align: right; width: 25%;">{{ tableData.beforeMoney | formatCurrency }}</td>
            <td style="text-align: right; width: 25%;">{{ tableData.spendMoney | formatCurrency }}</td>
            <td style="text-align: right; width: 25%; color: red;">{{ tableData.afterMoney | formatCurrency }}</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: left; font-style: italic;">
              <i>Số tiền bằng chữ: {{ tableData.readMoney }}</i>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</template>
<script>
export default {
  name: 'printNullTable',
  props: {
    tableData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      listData: [],
      spendMoney: 0
    }
  },
  mounted() {
    this.getListData()
  },
  methods:{
    getListData(){
      for (let i = 1; i <= 20; i++) {
        if (i <10){
          if (this.tableData[`nameK0${i}`] !== `Khoản ${i}`) {
            let employee = {};
            employee.name = this.tableData[`nameK0${i}`];
            employee.quantity = this.tableData[`quantityK0${i}`];
            employee.price = this.tableData[`priceK0${i}`];
            employee.sumMoney = this.tableData[`moneyK0${i}`];
            this.listData.push(employee);
          }
        }else {
          if (this.tableData[`nameK${i}`] !== `Khoản ${i}`) {
            let employee = {};
            employee.name = this.tableData[`nameK${i}`];
            employee.quantity = this.tableData[`quantityK${i}`];
            employee.price = this.tableData[`priceK${i}`];
            employee.sumMoney = this.tableData[`moneyK${i}`];
            this.listData.push(employee);
          }
        }
      }
      console.log(this.listData)
    },
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}
</style>